import { IAuthState } from '@pixcap/ui-core/models/store/auth.interface';

export const getDefaultState = function (): IAuthState {
	return {
		idToken: '',
		isSubmitting: false, // disable re-submitting when a request is in progress
		anonymousSession: null,
		loginEmail: '',
		resetPasswordCode: '',
		isRefreshingToken: false,
	};
};

export default (): IAuthState => getDefaultState();
