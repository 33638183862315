import { CodePromotion, GetterTypes, Promotion } from '@pixcap/ui-core/models/store/app.interface';
import { GetterTypes as UserGetterTypes, NAMESPACE as USER_NAMESPACE } from '@pixcap/ui-core/models/store/user.interface';
import SpecialPromos from '@pixcap/ui-core/static/special-promos.json';

export default {
	[GetterTypes.CURRENT_PROMOTION]: (state, getters, rootState, rootGetters) => {
		let promoValue = null;
		if (SpecialPromos) {
			const currentDate = new Date();
			SpecialPromos.some((promo: Promotion) => {
				if (currentDate > new Date(promo.startDate) && currentDate < new Date(promo.expiredDate)) {
					promoValue = promo;
					return true;
				} else return false;
			});
		}
		if (promoValue && promoValue?.type === 'DISCOUNT_PROMO' && rootGetters[`${USER_NAMESPACE}/${UserGetterTypes.IS_PRO_OR_ABOVE}`]) {
			return null;
		}
		if (promoValue && promoValue?.type === 'CODE_DISCOUNT_PROMO') {
			return null;
		}
		return promoValue;
	},
	[GetterTypes.CODE_PROMOTION]: (state, getters, rootState, rootGetters) => {
		let promoValue = null;
		if (SpecialPromos) {
			const currentDate = new Date();
			SpecialPromos.some((promo: CodePromotion) => {
				if (currentDate > new Date(promo.startDate) && currentDate < new Date(promo.expiredDate)) {
					promoValue = promo;
					return true;
				} else return false;
			});
		}
		if (promoValue && promoValue?.type !== 'CODE_DISCOUNT_PROMO') {
			return null;
		}

		if (promoValue && promoValue?.type === 'CODE_DISCOUNT_PROMO' && rootGetters[`${USER_NAMESPACE}/${UserGetterTypes.IS_PRO_OR_ABOVE}`]) {
			return null;
		}
		return promoValue;
	},
};
